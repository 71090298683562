// InvestingWithGrandviewContent.js
import React from "react";
import { Link } from "react-router-dom";
import Flyout from "../components/Flyout";


const InvestingWithGrandviewContent = () => {
  return (
    <div className=" text-lg w-64  p-6 shadow-xl z-50 font-open-sans  uppercase">
      <div className="   mb-3 space-y-5 relative   ">
        <a className="block  relative  ">
          <Flyout>
            <Link
              to="/the-grandview-philosophy"
              className=" text-teal-green text-center  transition-colors duration-200 ease-in-out no-underline "
            >
              The Grandview Philosophy
            </Link>
          </Flyout>
        </a>

        <a className="block    ">
          <Flyout>
            <Link
              to="/start-relation"
              className=" text-teal-green text-center  transition-colors duration-200 ease-in-out no-underline "
            >
               Start With A Relationship
            </Link>
          </Flyout>
        </a>

        <a className="block   ">
          <Flyout>
            <Link
              to="/market"
              className=" text-teal-green text-center  transition-colors duration-200 ease-in-out no-underline "
            >
              What is the exempt market ?
            </Link>
          </Flyout>
        </a>

        <a className="block    ">
          <Flyout>
            <Link
              to="/why-emd"
              className=" text-teal-green text-center  transition-colors duration-200 ease-in-out no-underline "
            >
              Why Invest in the <br />exempt market?
            </Link>
          </Flyout>
        </a>
        
      </div>
    </div>
  );
};

export default InvestingWithGrandviewContent;
