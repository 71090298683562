import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

const Flyout = ({ children, href, FlyoutContent }) => {
  const [open, setOpen] = useState(false);

  

  return (
    <div
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className="relative w-fit h-fit"
    >
      <a
        href={href}
        className="relative text-red"
        style={{ textDecoration: "none" }}
      >
        {children}
        <span
          style={{
            transform: open ? "scaleX(1)" : "scaleX(0)",
          }}
          className="absolute -bottom-2 -left-2 -right-2 h-1 origin-left scale-x-0 rounded-full bg-slate-grey transition-transform duration-300 ease-out"
        />
      </a>
    </div>
  );
};

export default Flyout;
