import { useTranslation } from "react-i18next";
const Hero = () => {
  const { t } = useTranslation("hero");
  return (
    <div
      className="self-stretch rounded-31xl bg-light2-grey shadow-[0px_0px_20px_rgba(0,_0,_0,_0.15)] overflow-hidden 
                    flex flex-row items-center justify-between relative max-w-full gap-[20px] text-left text-xl text-teal-green
                    font-source-sans-pro mq1500:flex-wrap mq450:flex-col mq450:border-none mq450:px-0 mq450:py-0"
    >
      <div
        className="flex flex-col items-start justify-start py-5 pr-0 pl-20 box-border 
                      gap-[30px] min-w-[723px] w-full 
                      mq975:gap-[15px]
                      mq975:pl-10 mq975:box-border mq975:min-w-full mq450:order-last
                      mq450:px-[20px] mq450:py-[20px]"
      >
        <h1
          className=" m-0 self-stretch relative text-41xl leading-[75px] 
                        font-normal font-inherit mq975:text-29xl mq975:leading-[60px]
                        mq450:text-11xl mq450:leading-[45px]"
        >
          {t("head1")}
        </h1>
        <h2 className="self-stretch relative text-3xl sm:text-4xl md:text-5xl lg:text-6xl leading-tight font-bold text-dark-green mb-4">
          {t("head2")}
        </h2>
        <div className="self-stretch text-justify relative leading-[34px] font-open-sans mq450:text-[13px] mq450:leading-[27px]">
          {t("body")}
        </div>
        <div className="w-[487px] flex flex-row flex-wrap items-start justify-start gap-[30px] max-w-full text-azure-blue font-aeroport mq450:gap-[10px] mq450:w-full">
          <button
            className="button flex items-center justify-center cursor-pointer [border:none] py-[30px] px-[30px] 
          bg-teal-green hover:bg-mint-green w-[171px] rounded-31xl overflow-hidden shrink-0
          flex-col box-border whitespace-nowrap mq450:py-[15px]
          mq450:px-[15px] mq450:w-[99px] mq450:h-[48px] transition-colors duration-300"
          >
            <b className="text-mint-green h-[27px] relative text-xl inline-block font-aeroport text-center mq450:text-[12px]">
              Learn More
            </b>
          </button>
          <div className="flex-1 rounded-31xl box-border overflow-hidden flex items-center justify-center py-[30px] px-[30px] min-w-[186px] whitespace-nowrap border-[1px] border-solid border-mint-green mq450:py-[15px] mq450:px-[15px] mq450:w-[168px] mq450:h-[48px]">
            <b className="text-teal-green text-center mq450:text-[12px]">
              What is Private Equity?
            </b>
          </div>
        </div>
      </div>
      <div className="relative w-full h-auto mq450:order-first">
        <img
          className="w-full relative rounded-tl-none rounded-tr-31xl rounded-b-none max-h-full object-cover max-w-full"
          loading="lazy"
          alt=""
          src="/man.png"
        />
        <img
          className="absolute top-0 left-0 w-full h-full object-cover mix-blend-darken z-[1]"
          alt=""
          src="/green.png"
        />
      </div>
    </div>
  );
};

export default Hero;
