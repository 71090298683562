import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";

const HomepageFAQs = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {["panel1", "panel2", "panel3", "panel4"].map((panel) => (
        <Accordion
          key={panel}
          expanded={expanded === panel}
          onChange={handleChange(panel)}
          className="w-[1200px] rounded-11xl box-border overflow-hidden flex flex-row flex-wrap items-center justify-center py-5 pr-[131px] pl-[49px] gap-[50px] max-w-full border-[1px] border-solid border-ocean-blue mq975:gap-[25px] mq975:pr-8 mq975:box-border mq1500:pl-6 mq1500:pr-[65px] mq1500:box-border my-2"
        >
          <AccordionSummary
            expandIcon={<ArrowDownwardOutlinedIcon />}
            aria-controls={`${panel}-content`}
            id={`${panel}-header`}
            className="h-[33.2px] w-[35.2px] relative"
          >
            <Typography className="m-0 flex-1 relative text-inherit leading-[60px] font-normal font-inherit inline-block min-w-[326px] max-w-full mq975:text-13xl mq975:leading-[48px] mq450:text-5xl mq450:leading-[36px]">
              {/* Dynamic question based on panel */}
              {panel === "panel1" && "What is an EMD product?"}
              {panel === "panel2" && "What is an accredited investor?"}
              {panel === "panel3" && "What is a REIT?"}
              {panel === "panel4" && "How do I assess my risk profile?"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="text-anchor-grey font-open-sans">
            <Typography>
              {/* Dynamic answer based on panel */}
              {panel === "panel1" &&
                "An EMD product refers to Exempt Market Dealer offerings."}
              {panel === "panel2" &&
                "An accredited investor is someone with a high net worth who meets certain financial criteria."}
              {panel === "panel3" &&
                "A REIT is a Real Estate Investment Trust, allowing investment in property portfolios."}
              {panel === "panel4" &&
                "Assessing your risk profile involves understanding your investment goals and risk tolerance."}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}

      <button className="cursor-pointer [border:none] p-[30px] bg-ocean-blue rounded-31xl overflow-hidden flex flex-col items-center justify-center whitespace-nowrap hover:bg-darkblue text-light-grey text-xl font-abeezee min-w-[121px]">
        See All FAQs
      </button>
    </>
  );
};

export default HomepageFAQs;
