import React, { useState } from "react";
import { GoArrowDown } from "react-icons/go";

const AccordionItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-white py-2 w-full mx-auto border-[1px] border-solid border-teal-green rounded-11xl overflow-hidden">
      <button
        onClick={toggleAccordion}
        className="bg-white flex items-center justify-start w-full p-5 text-left focus:outline-none"
      >
        <div className="flex items-center">
          <GoArrowDown
            className={`text-teal-green h-6 w-6 sm:h-8 sm:w-8 md:h-10 md:w-10 lg:h-12 lg:w-12 transform transition-transform duration-200 ease-out ${
              isOpen ? "rotate-180" : ""
            }`}
          />
          <span className="ml-4 text-base sm:text-lg md:text-xl lg:text-2xl text-anchor-grey">
            {question}
          </span>
        </div>
      </button>
      <div
        className={`ml-4 overflow-hidden transition-all duration-300 ease-in-out text-anchor-grey text-base sm:text-lg md:text-xl lg:text-2xl ${
          isOpen ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <div className="overflow-hidden">{answer}</div>
      </div>
    </div>
  );
};

const HomepageFAQs = () => {
  const faqs = [
    {
      question: "What is an EMD product?",
      answer:
        "An EMD product refers to a product available in the exempt market that does not require a prospectus to be issued.",
    },
    {
      question: "What is an accredited investor?",
      answer:
        "An accredited investor is an individual with a net worth exceeding $1 million, not including the value of their primary residence, or with an income exceeding $200,000 in each of the past two years.",
    },
    {
      question: "What is a REIT?",
      answer:
        "A REIT, or Real Estate Investment Trust, is a company that owns, operates, or finances income-producing real estate.",
    },
    {
      question: "How do I assess my risk profile?",
      answer:
        "Assessing your risk profile typically involves evaluating your investment objectives, time horizon, and comfort with volatility.",
    },
  ];

  return (
    <section className="shadow-[0px_0px_20px_rgba(0,_0,_0,_0.15)] rounded-3xl self-stretch bg-light-grey3 overflow-hidden flex flex-col items-center justify-start py-10 px-5 sm:py-12 sm:px-8 md:py-14 md:px-10 lg:py-16 lg:px-12 xl:py-20 xl:px-16 gap-8 max-w-full text-left text-xl text-anchor-grey font-source-sans-pro">
      <h1 className="text-teal-green m-0 w-full max-w-4xl text-2xl sm:text-3xl md:text-[35px] lg:text-[48px] xl:text-[48px] font-normal text-center">
        Frequently Asked Questions
      </h1>
      <div className="space-y-4 sm:space-y-6 md:space-y-8 lg:space-y-10">
        {faqs.map((faq, index) => (
          <AccordionItem
            key={index}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </div>
      <button className="cursor-pointer py-[30px] px-[29px] bg-transparent rounded-31xl overflow-hidden flex flex-col items-center justify-center whitespace-nowrap border-[1px] border-solid border-teal-green hover:bg-mint-green hover:box-border hover:border-[1px] hover:border-solid hover:border-mint-green">
        <b className="h-[27px] relative text-xl inline-block font-abeezee  text-left min-w-[111px]">
          Learn More
        </b>
      </button>
    </section>
  );
};

export default HomepageFAQs;
