import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import InvestingWithGrandviewContent from "../components/InvestingWithGrandviewContent";
import AboutContent from "../components/AboutContent";
import FlyoutLink from "../components/FlyoutLink";
import Flyout from "../components/Flyout";
import { useTranslation } from "react-i18next";
import { MdOutlineMenu } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

const Header = ({
  langMenuWidth,
  menuItemAlignSelf,
  investingWithGrandviewFlex,
  menuItemAlignSelf1,
  menuItemLineHeight,
}) => {
  const { t, i18n } = useTranslation("header");
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const mainNavStyle = useMemo(() => {
    return {
      width: langMenuWidth,
    };
  }, [langMenuWidth]);

  const mainMenuStyle = useMemo(() => {
    return {
      alignSelf: menuItemAlignSelf,
    };
  }, [menuItemAlignSelf]);

  const menuItemStyle = useMemo(() => {
    return {
      flex: investingWithGrandviewFlex,
    };
  }, [investingWithGrandviewFlex]);

  const investingWithGrandviewStyle = useMemo(() => {
    return {
      alignSelf: menuItemAlignSelf1,
      lineHeight: menuItemLineHeight,
    };
  }, [menuItemAlignSelf1, menuItemLineHeight]);

  const LanguageToggle = () => (
    <div className="flex items-center space-x-2">
      <button
        onClick={() => changeLanguage("en")}
        className={`px-2 py-1 rounded ${i18n.language === "en" ? "bg-green-700  text-white" : "bg-light-grey  text-black"}`}
      >
        EN
      </button>
      <button
        onClick={() => changeLanguage("zh")}
        className={`px-2 py-1 rounded ${i18n.language === "zh" ? "bg-green-700 text-white" : "bg-gray-300 text-black"}`}
      >
        ZH
      </button>
    </div>
  );

  return (
    <header className="bg-light-grey fixed top-0 left-0 self-stretch px-5 sm:px-10 lg:px-15 z-30 flex flex-row items-center justify-between py-15 sm:pt-6 lg:py-[10px]  box-border min-h-[100px] sm:min-h-[150px] lg:min-h-[199px] w-full gap-[8px] lg:text-[10px] text-left xl:text-[20px] text-ocean-blue font-aeroport">
      <Link to="/">
        <img
          className="h-auto w-[180px] xs:w-[200px] sm:w-[250px] md:w-[280px] lg:w-[300px] relative object-contain pl-4 sm:pl-0 xm:pl-0 lg:pl-0"
          loading="lazy"
          alt="Grandview Capital logo"
          src="/grandviewcap-logo-logo-b-1@2x.png"
        />
      </Link>
      <div className="flex items-center gap-4 lg:hidden">
        <Link
          to="https://grandviewcapital-admin.firebaseapp.com/"
          className="text-teal-green text-[10px] lg:text-xl transition-colors duration-200 ease-in-out no-underline font-bold"
          aria-label={t("CLIENTLOGIN")}
        >
          {t("CLIENTLOGIN")}
        </Link>
        <div className="lg:hidden">
          <button
            onClick={toggleMenu}
            className="relative bg-light-grey p-2 rounded"
          >
            {menuOpen ? (
              <RxCross2 size={30} color="black" />
            ) : (
              <MdOutlineMenu size={30} color="black" />
            )}
          </button>
        </div>
      </div>

      <div
        className={`lg:flex ${menuOpen ? "block" : "hidden"} lg:flex`}
        style={mainNavStyle}
      >
        <div
          className="lg:flex lg:flex-row lg:items-center lg:justify-end lg:gap-[30px] text-xl text-black1"
          style={mainMenuStyle}
        >
          {/* Mobile sidebar */}

          {menuOpen && (
            <div className="fixed inset-0 bg-light-grey z-40 flex flex-col p-4">
              <div className="flex justify-between items-center w-full mb-4">
                <button
                  onClick={toggleMenu}
                  className="bg-light-grey p-2 rounded"
                >
                  <RxCross2 size={30} color="black" />
                </button>
              </div>
              <div className="flex flex-col items-center gap-4 w-full">
                <FlyoutLink
                  href="#"
                  className="hover:underline relative text-xl"
                  FlyoutContent={InvestingWithGrandviewContent}
                >
                  {t("INVESTINGWITHGRANDVIEW")}
                </FlyoutLink>
                <Link
                  to="#"
                  className="text-teal-green text-xl transition-colors duration-200 ease-in-out no-underline"
                >
                  {t("INVESTMENTOPTIONS")}
                </Link>
                <FlyoutLink
                  href="#"
                  className="hover:underline text-xl text-red-500"
                  FlyoutContent={AboutContent}
                >
                  {t("ABOUT")}
                </FlyoutLink>
                <Link
                  to="https://forms.gle/YgNmwb274nN3yRZRA"
                  className="text-teal-green text-xl transition-colors duration-200 ease-in-out no-underline"
                >
                  {t("CONTACT")}
                </Link>
                <Link
                  to="https://grandviewcapital-admin.firebaseapp.com/"
                  className="text-teal-green text-xl transition-colors duration-200 ease-in-out no-underline"
                  aria-label={t("CLIENTLOGIN")}
                >
                  {t("CLIENTLOGIN")}
                </Link>
                <LanguageToggle />
                <Link to="/contact" className="no-underline">
                  <button className="button cursor-pointer border-none p-5 bg-teal-green hover:bg-mint-green rounded-31xl overflow-hidden flex flex-col items-center justify-center whitespace-nowrap">
                    <b className="relative text-xl inline-block font-abeezee text-mint-green">
                      {t("MEETWITHUS")}
                    </b>
                  </button>
                </Link>
              </div>
            </div>
          )}

          {/* Desktop navigation */}
          <div className="hidden lg:flex lg:flex-row lg:items-center lg:justify-end lg:gap-[30px] text-xl text-black1">
            <div
              className="flex-1 flex flex-col items-center justify-center py-5 px-2.5 relative"
              style={menuItemStyle}
            >
              <div
                className="leading-[23px] whitespace-nowrap"
                style={investingWithGrandviewStyle}
              >
                <FlyoutLink
                  href="#"
                  className="hover:underline relative"
                  FlyoutContent={InvestingWithGrandviewContent}
                >
                  {t("INVESTINGWITHGRANDVIEW")}
                </FlyoutLink>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center py-5 px-2.5">
              <div className="h-[27px] relative inline-block min-w-[76px]">
                <Flyout>
                  <Link
                    to="#"
                    className="text-teal-green text-center transition-colors duration-200 ease-in-out no-underline"
                  >
                    {t("INVESTMENTOPTIONS")}
                  </Link>
                </Flyout>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center py-5 px-[9px]">
              <div className="h-[27px] relative inline-block min-w-[58px]">
                <FlyoutLink
                  href="#"
                  className="hover:underline text-red-500"
                  FlyoutContent={AboutContent}
                >
                  {t("ABOUT")}
                </FlyoutLink>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center py-5 px-2.5">
              <div className="h-[27px] relative inline-block min-w-[76px]">
                <Flyout>
                  <Link
                    to="https://forms.gle/YgNmwb274nN3yRZRA"
                    className="text-teal-green text-center transition-colors duration-200 ease-in-out no-underline"
                  >
                    {t("CONTACT")}
                  </Link>
                </Flyout>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center py-5 px-2.5">
              <div className="h-[27px] relative inline-block min-w-[76px]">
                <Flyout>
                  <Link
                    to="https://grandviewcapital-admin.firebaseapp.com/"
                    className="text-teal-green text-center transition-colors duration-200 ease-in-out no-underline"
                  >
                    {t("CLIENTLOGIN")}
                  </Link>
                </Flyout>
              </div>
            </div>
            <div className="relative">
              {/* 添加相对定位 */}
              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-5">
                <LanguageToggle />
              </div>
              <Link to="/contact" className="no-underline">
                <button className="button cursor-pointer border-none p-5 bg-teal-green hover:bg-mint-green rounded-31xl overflow-hidden flex items-center justify-center whitespace-nowrap">
                  <b className="relative text-xl inline-block font-abeezee text-mint-green">
                    {t("MEETWITHUS")}
                  </b>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
