import { useTranslation } from "react-i18next";

const Market = () => {
  const { t } = useTranslation("market");

  return (
    <div className="self-stretch flex flex-col items-center justify-center py-10 px-5 sm:px-10 lg:px-20 box-border gap-20 max-w-full text-left text-xl text-teal-green font-open-sans">
      {/* Group 1: Image, Title, Subheading, Paragraph 1 */}
      <div className="w-full flex flex-col lg:flex-row-reverse xxl:flex-row-reverse items-center justify-center lg:gap-[50px] xxl:gap-[80px]">
        <img
          className="shadow-[0px_0px_20px_rgba(0,_0,_0,_0.15)] rounded-3xl w-full object-cover mix-blend-multiply
            h-[309px] sm:h-[307px] md:h-[310px] lg:h-[404px] xxl:h-[644px] xl:h-[209px]
            max-w-[375px] sm:max-w-[423px] md:max-w-[770px] lg:max-w-[524px] xxl:max-w-[519px]"
          loading="lazy"
          alt=""
          src="/van_2_1.jpg"
        />
        <div className="flex-1 flex flex-col items-start justify-start gap-[30px] w-full lg:max-w-[50%] xxl:gap-[30px]">
          <h1 className="m-0 self-stretch relative text-4xl md:text-5xl lg:text-[41px] leading-tight lg:leading-[75px] font-normal font-source-sans-pro xxl:text-41xl xxl:leading-[75px]">
            {t("title")}
          </h1>
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed font-extrabold xxl:leading-[34px]">
            {t("subheading")}
          </div>
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed xxl:leading-[34px] text-justify">
            {t("paragraph1")}
          </div>
        </div>
      </div>
      
      {/* Group 2: Image, Paragraph 2 Title, Paragraph 2, Paragraph 3 */}
      <div className="w-full flex flex-col lg:flex-row xxl:flex-row items-center justify-center gap-10 xl:gap-5 xxl:gap-[80px]">
        <img
          className="shadow-[0px_0px_20px_rgba(0,_0,_0,_0.15)] rounded-3xl w-full object-cover mix-blend-multiply
            h-[309px] sm:h-[307px] md:h-[310px] lg:h-[404px] xxl:h-[644px]
            max-w-[375px] sm:max-w-[423px] md:max-w-[770px] lg:max-w-[524px] xxl:max-w-[519px]"
          loading="lazy"
          alt=""
          src="/van_2_2.jpg"
        />
        <div className="flex-1 flex flex-col items-start justify-start gap-[30px] w-full lg:max-w-[50%] xxl:gap-[30px]">
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed font-extrabold xxl:leading-[34px]">
            {t("summary_title")}
          </div>
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed font-extrabold xxl:leading-[34px]">
            {t("paragraph2_title")}
          </div>
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed xxl:leading-[34px] text-justify">
            {t("paragraph2")}
          </div>
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed font-extrabold xxl:leading-[34px]">
            {t("paragraph3_title")}
          </div>
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed xxl:leading-[34px] text-justify">
            {t("paragraph3")}
          </div>
        </div>
      </div>
      
      {/* Group 3: Image, Paragraph 4, Paragraph 5, Paragraph 6 */}
      <div className="w-full flex flex-col lg:flex-row-reverse xxl:flex-row-reverse items-center justify-center lg:gap-[50px] xxl:gap-[80px] text-justify">
        <img
          className="shadow-[0px_0px_20px_rgba(0,_0,_0,_0.15)] rounded-3xl w-full object-cover mix-blend-multiply
            h-[309px] sm:h-[307px] md:h-[310px] lg:h-[404px] xxl:h-[644px]
            max-w-[375px] sm:max-w-[423px] md:max-w-[770px] lg:max-w-[524px] xxl:max-w-[519px]"
          loading="lazy"
          alt=""
          src="/van_2_4.jpg"
        />
        <div className="flex-1 flex flex-col items-start justify-start gap-[30px] w-full lg:max-w-[50%] xxl:gap-[30px]">
        <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed font-extrabold xxl:leading-[34px]">
            {t("paragraph4_title")}
          </div>
          
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed xxl:leading-[34px]">
            {t("paragraph4")}
          </div>
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed font-extrabold xxl:leading-[34px]">
            {t("paragraph5_title")}
          </div>
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed xxl:leading-[34px]">
            {t("paragraph5")}
          </div>
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed font-extrabold xxl:leading-[34px]">
            {t("paragraph6_title")}
          </div>
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed xxl:leading-[34px]">
            {t("paragraph6")}
          </div>
        </div>
      </div>
      
      {/* Group 4: Image, Paragraph 7, Paragraph 8, Paragraph 9 */}
      <div className="w-full flex flex-col lg:flex-row xxl:flex-row items-center justify-center gap-10 xl:gap-5 xxl:gap-[80px] text-justify">
        <img
          className="shadow-[0px_0px_20px_rgba(0,_0,_0,_0.15)] rounded-3xl w-full object-cover mix-blend-multiply
            h-[309px] sm:h-[307px] md:h-[310px] lg:h-[404px] xxl:h-[644px]
            max-w-[375px] sm:max-w-[423px] md:max-w-[770px] lg:max-w-[524px] xxl:max-w-[519px]"
          alt=""
          src="/van_2_5.jpg"
        />
        <div className="flex-1 flex flex-col items-start justify-start gap-[30px] w-full lg:max-w-[50%] xxl:gap-[30px]">
        <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed font-extrabold xxl:leading-[34px]">
            {t("paragraph7_title")}
          </div>
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed xxl:leading-[34px]">
            {t("paragraph7")}
          </div>
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed font-extrabold xxl:leading-[34px]">
            {t("paragraph8_title")}
          </div>
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed xxl:leading-[34px]">
            {t("paragraph8")}
          </div>
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed font-extrabold xxl:leading-[34px]">
            {t("paragraph9_title")}
          </div>
          <div className="self-stretch relative text-lg md:text-xl lg:text-xl leading-relaxed xxl:leading-[34px]">
            {t("paragraph9")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Market;