import { useMemo } from "react";
import { Link } from "react-router-dom";

const InvestmentCard = ({
  heading1,
  subtitles,
  growth,
  description,
  return: returnLabel,
  risk,
  timeline,
  returnValue,
  riskValue,
  timelineValue,
  image,
  learnMoreUrl, // 添加这一行
}) => {
  const bgImgStyle = useMemo(
    () => ({
      backgroundImage: `url(${image})`,
    }),
    [image]
  );

  return (
    <div className="w-full max-w-[600px] h-[800px] rounded-xl bg-light-grey2 shadow-lg overflow-hidden flex flex-col items-start justify-between pb-6 box-border text-center text-xl text-light-grey font-aeroport">
      <div
        className="self-stretch flex-grow flex flex-col items-center justify-center py-16 px-4 bg-cover bg-no-repeat bg-center"
        style={bgImgStyle}
      >
        <div className="text-center">
          {heading1 && (
            <h1 className="m-0 relative text-2xl sm:text-3xl md:text-4xl leading-tight font-normal font-source-sans-pro text-white text-shadow mb-2">
              {heading1}
            </h1>
          )}
          <b className="block relative leading-tight text-lg sm:text-xl md:text-2xl text-white text-shadow">
            {growth}
          </b>
          <h2 className="m-0 relative text-xl sm:text-2xl md:text-3xl leading-tight font-normal font-source-sans-pro text-white text-shadow">
            {subtitles}
          </h2>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-end px-4 sm:px-8 box-border max-w-full text-left text-anchor-grey font-open-sans mt-8">
        <div className="w-full flex flex-col items-start justify-start gap-6">
          {description && (
            <p className="text-justify self-stretch text-sm sm:text-base leading-relaxed">
              {description}
            </p>
          )}
          <div className="w-full flex flex-col items-start justify-start gap-4">
            {[
              { label: "Return", value: returnValue, text: returnLabel },
              { label: "Risk", value: riskValue, text: risk },
              { label: "Timeline", value: timelineValue, text: timeline },
            ].map(({ label, value, text }) => (
              <div
                key={label}
                className="w-full flex flex-row items-center justify-between gap-2"
              >
                <b className="w-1/4 text-sm sm:text-base leading-tight">
                  {label}
                </b>
                <div className="w-1/2 h-4 rounded-full bg-gainsboro-100 overflow-hidden">
                  <div
                    className="h-full bg-blue-500"
                    style={{ width: `${value}%` }}
                  />
                </div>
                <div className="w-1/4 text-sm sm:text-base leading-tight text-right">
                  {text}
                </div>
              </div>
            ))}
          </div>
          <div className="self-stretch flex flex-row items-start justify-center py-2">
            <Link to={learnMoreUrl} className="no-underline">
              <button className="group cursor-pointer border-none py-3 px-6 bg-teal-green hover:bg-mint-green rounded-full overflow-hidden flex items-center justify-center whitespace-nowrap transition-colors duration-300">
                <b className="text-mint-green group-hover:text-teal-green text-base sm:text-lg font-aeroport transition-colors duration-300">
                  Learn More
                </b>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentCard;
