import Header from "../components/Header";
import FrameComponent from "../components/FrameComponent";
import Test from "../pages/test";

const HomepageDesktop = () => {
  return (
    <div className="w-full relative bg-light-grey overflow-hidden flex flex-col items-start justify-start tracking-[normal]">
      <section className="self-stretch [background:linear-gradient(180deg,_#f5f5f5,_#fff)] shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] flex flex-col items-start justify-start pt-0 px-[50px] pb-[75.2px] box-border max-w-full text-left text-81xl text-ocean-blue font-source-sans-pro mq975:pl-[25px] mq975:pr-[25px] mq975:pb-[21px] mq975:box-border mq1500:pb-8 mq1500:box-border">
        <Test
          langMenuWidth="unset"
          menuItemAlignSelf="unset"
          investingWithGrandviewFlex="unset"
          menuItemAlignSelf1="unset"
          menuItemLineHeight="unset"
        />
      </section>

      
      
    </div>
  );
};

export default HomepageDesktop;
