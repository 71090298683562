const POST = ({ pOSTTHUMBNAIL }) => {
  return (
    <div className="self-stretch flex flex-row items-center justify-center gap-[60px] max-w-full shrink-0 text-center text-smi text-black-500 font-graphik mq900:flex-wrap mq1300:gap-[60px_30px]">
      <div className="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[396px] max-w-full mq900:min-w-full">
        <div className="self-stretch flex flex-col items-start justify-center gap-[24px]">
          <div className="flex flex-row items-start justify-start">
            <button className="cursor-pointer [border:none] pt-2 px-[11px] pb-2.5 bg-blue-100 rounded-tl-md rounded-tr-none rounded-br-none rounded-bl-md flex flex-row items-center justify-center whitespace-nowrap hover:bg-lavender">
              <div className="w-[99px] relative text-smi leading-[120%] uppercase font-semibold font-graphik text-black-500 text-center inline-block min-w-[99px]">
                Design tools
              </div>
            </button>
            <div className="rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none bg-white flex flex-row items-center justify-center pt-2 px-2.5 pb-2.5 whitespace-nowrap">
              <div className="w-[110px] relative leading-[120%] uppercase font-medium inline-block min-w-[110px]">{`AUGust 13, 2021 `}</div>
            </div>
          </div>
          <h3 className="m-0 self-stretch relative text-9xl leading-[130%] font-bold font-inherit text-left mq450:text-3xl mq450:leading-[29px]">
            10 Hilarious Cartoons That Depict Real-Life Problems of Programmers
          </h3>
        </div>
        <div className="self-stretch relative text-lg leading-[150%] text-left">
          Redefined the user acquisition and redesigned the onboarding
          experience, all within 3 working weeks.
        </div>
      </div>
      <img
        className="h-[210px] w-[300px] relative rounded-lg object-cover mq900:flex-1"
        loading="lazy"
        alt=""
        src={pOSTTHUMBNAIL}
      />
    </div>
  );
};

export default POST;
