const PageElementComp = () => {
  return (
    <footer className="w-[1537px] flex flex-row items-start justify-start py-0 px-2.5 box-border max-w-full text-left text-xl text-slate-grey font-open-sans">
      <div className="flex-1 flex flex-row items-start justify-between max-w-full gap-[20px] mq1300:flex-wrap mq1300:justify-center">
        <div className="h-[280px] w-[345px] flex flex-col items-start justify-start gap-[10px] min-w-[345px] max-w-full mq450:min-w-full mq1300:flex-1">
          <img
            className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
            loading="lazy"
            alt=""
            src="/grandviewcap-logo-logo-b-2@2x.png"
          />
          <div className="w-[274px] relative leading-[34px] inline-block box-border pr-5 mq450:text-base mq450:leading-[27px]">
            <p className="m-0">email@grandviewcapital.co</p>
            <p className="m-0">000.000.0000</p>
            <p className="m-0">123 Street Address,</p>
            <p className="m-0">Vancouver, BC V6V6V6</p>
          </div>
        </div>
        <div className="w-[799px] flex flex-row items-start justify-start gap-[80px] min-w-[799px] max-w-full text-anchor-grey font-abeezee mq450:gap-[80px_20px] mq900:flex-wrap mq900:gap-[80px_40px] mq900:min-w-full mq1300:flex-1">
          <div className="flex-1 flex flex-col items-start justify-start gap-[10px] min-w-[160px]">
            <b className="w-[111px] relative leading-[34px] inline-block box-border pr-5 mq450:text-base mq450:leading-[27px]">
              Investing
            </b>
            <div className="relative leading-[50px] font-open-sans mq450:text-base mq450:leading-[40px]">
              <p className="m-0">Investment Philosophy</p>
              <p className="m-0">Why EMD?</p>
              <p className="m-0">Investment Options</p>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[10px] min-w-[160px]">
            <b className="w-20 relative leading-[34px] inline-block box-border pr-5 mq450:text-base mq450:leading-[27px]">
              About
            </b>
            <div className="self-stretch relative leading-[50px] font-open-sans mq450:text-base mq450:leading-[40px]">
              <p className="m-0">About Us</p>
              <p className="m-0">Our Advisory Team</p>
              <p className="m-0">Accreditations</p>
              <p className="m-0">Contact Us</p>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[10px] min-w-[160px]">
            <b className="w-[73px] relative leading-[34px] inline-block box-border pr-5 mq450:text-base mq450:leading-[27px]">
              Legal
            </b>
            <div className="self-stretch relative leading-[50px] font-open-sans mq450:text-base mq450:leading-[40px]">
              <p className="m-0">EMD Regulations</p>
              <p className="m-0">Legal Disclosures</p>
              <p className="m-0">Terms of Use</p>
              <p className="m-0">Privacy Policy</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PageElementComp;
