import React from 'react';
import InvestmentCard from "../components/InvestmentCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useTranslation } from "react-i18next";
import "../global.css";

const Background = () => {
  const { t } = useTranslation("card");

  const investmentCards = [
    {
      heading1: t("heading1"),
      growth: t("growth"),
      subtitles: t("subtitles"),
      description: t("description"),
      return: "Very High",
      risk: "High",
      timeline: "Medium",
      returnValue: 100,
      riskValue: 75,
      timelineValue: 50,
      image: "/bgimg@3x.png",
      learnMoreUrl: "https://regencycapital.ca/",
    },
    {
      heading1: t("heading2"),
      growth: t("growth2"),
      subtitles: t("subtitles2"),
      description: t("description2"),
      return: "Medium",
      risk: "Low",
      timeline: "Short",
      returnValue: 50,
      riskValue: 25,
      timelineValue: 25,
      image: "/bgimg1@3x.png",
      learnMoreUrl: "https://avenuelivingam.com/funds/core-trust/",
    },
    {
      heading1: t("heading3"),
      growth: t("growth3"),
      subtitles: t("subtitles3"),
      description: t("description3"),
      return: "Medium",
      risk: "Medium",
      timeline: "Medium",
      returnValue: 50,
      riskValue: 50,
      timelineValue: 50,
      image: "/bgimg2@3x.png",
      learnMoreUrl: "https://centurion.ca/investment-solutions/centurion-apartment-reit",
    },
    {
      heading1: t("heading4"),
      growth: t("growth4"),
      subtitles: t("subtitles4"),
      description: t("description4"),
      return: "Low",
      risk: "Low",
      timeline: "Short",
      returnValue: 25,
      riskValue: 25,
      timelineValue: 25,
      image: "/bgimg2@3x.png",
      learnMoreUrl: "https://www.incometrustone.com/",
    },
  ];

  return (
    <section className="rounded-xl bg-light-grey overflow-hidden flex flex-col items-center justify-start py-8 sm:py-12 md:py-16 lg:py-20 px-4 sm:px-8 md:px-12 lg:px-16 w-full text-center text-2xl sm:text-3xl md:text-4xl text-azure-blue font-abeezee">
      
      <h3 className="text-teal-green m-0 w-full max-w-4xl text-41xl leading-[75px] font-normal font-inherit text-center 
                     mq975:text-29xl mq975:leading-[60px] mq450:text-17xl mq450:leading-[45px] mb-8">
        {t("Featured Investment Opportunities")}
      </h3>

      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        pagination={{
          dynamicBullets: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="w-full"
      >
        {investmentCards.map((item, index) => (
          <SwiperSlide key={index}>
            <InvestmentCard {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Background;
