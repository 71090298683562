import POST from "./POST";

const ToolsPaneComp = () => {
  return (
    <section className="h-[2065.2px] w-[970px] flex flex-col items-start justify-start pt-0 px-0 pb-[292.2px] box-border gap-[60px] max-w-full text-left text-17xl text-black-500 font-graphik mq900:h-auto mq900:pb-20 mq900:box-border mq1300:gap-[30px_60px] mq1300:pb-[123px] mq1300:box-border">
      <h2 className="m-0 h-12 relative text-inherit font-bold font-inherit inline-block mq450:text-3xl mq900:text-10xl">
        Design Tools
      </h2>
      <div className="self-stretch h-[1725px] flex flex-col items-start justify-start pt-0 px-0 pb-[250px] box-border gap-[40px] max-w-full text-center text-smi mq900:h-auto mq900:gap-[20px_40px] mq900:pb-[68px] mq900:box-border mq1300:pb-[105px] mq1300:box-border">
        <POST pOSTTHUMBNAIL="/post-thumbnail@2x.png" />
        <div className="self-stretch h-px relative bg-blue-100" />
        <POST pOSTTHUMBNAIL="/post-thumbnail-1@2x.png" />
        <div className="self-stretch h-px relative bg-blue-100" />
        <POST pOSTTHUMBNAIL="/post-thumbnail-2@2x.png" />
        <div className="self-stretch h-px relative bg-blue-100" />
        <POST pOSTTHUMBNAIL="/post-thumbnail-3@2x.png" />
        <div className="self-stretch h-px relative bg-blue-100" />
        <POST pOSTTHUMBNAIL="/post-thumbnail-4@2x.png" />
        <div className="self-stretch h-px relative bg-blue-100" />
        <POST pOSTTHUMBNAIL="/post-thumbnail-5@2x.png" />
      </div>
      <div className="w-[834px] h-[172.2px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
        <div className="self-stretch w-32 flex flex-col items-start justify-start">
          <button className="cursor-pointer [border:none] py-[10.6px] px-5 bg-blue-100 self-stretch rounded-lg flex flex-col items-center justify-between box-border min-h-[42px]">
            <div className="self-stretch flex flex-row items-center justify-start">
              <div className="h-[21px] flex-1 relative text-sm leading-[120%] font-medium font-graphik text-black-500 text-center inline-block min-w-[88px]">
                More articles
              </div>
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};

export default ToolsPaneComp;
