import Header from "../components/Header";
import FrameComponent1 from "../components/FrameComponent1";

const Glossary = () => {
  return (
    <div className="w-full relative bg-light-grey overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-2.5 box-border gap-[41px] tracking-[normal] mq450:gap-[20px_41px]">
      <Header />
      <main className="self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-2.5 box-border max-w-full">
        <section className="h-[1386px] w-[1099px] flex flex-col items-start justify-start pt-0 px-0 pb-[503px] box-border gap-[80px] max-w-full text-center text-21xl text-black1 font-poppins mq900:gap-[20px_80px] mq900:pb-[138px] mq900:box-border mq1300:gap-[40px_80px] mq1300:pb-[213px] mq1300:box-border">
          <div className="self-stretch flex flex-row items-start justify-end pt-0 px-[15px] pb-[29px] box-border max-w-full text-gray-200 font-a">
            <div className="w-[963px] flex flex-col items-start justify-start gap-[72px] max-w-full mq900:gap-[18px_72px] mq1300:gap-[36px_72px]">
              <div className="self-stretch flex flex-row items-start justify-center py-0 pr-[21px] pl-5">
                <h1 className="m-0 w-[172px] relative text-inherit font-semibold font-inherit inline-block mq450:text-5xl mq900:text-13xl">
                  Glossary
                </h1>
              </div>
              <div className="rounded-lg bg-white shadow-[0px_4px_15px_rgba(187,_187,_187,_0.25)] overflow-x-auto flex flex-row items-start justify-start pt-3.5 pb-[19px] pr-12 pl-[51px] gap-[16.8px] text-xl text-gray-100 font-poppins border-[1px] border-solid border-gainsboro-300 mq1300:pl-[25px] mq1300:pr-6 mq1300:box-border">
                <div className="h-14 w-[963px] relative rounded-lg bg-white shadow-[0px_4px_15px_rgba(187,_187,_187,_0.25)] box-border shrink-0 hidden border-[1px] border-solid border-gainsboro-300" />
                <div className="w-11 shrink-0 flex flex-col items-start justify-start py-0 pr-1.5 pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 z-[1] mq450:text-base">
                    ALL
                  </div>
                </div>
                <div className="w-4 shrink-0 flex flex-col items-start justify-start py-0 pr-px pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[15px] z-[1] mq450:text-base">
                    A
                  </div>
                </div>
                <div className="w-[19px] shrink-0 flex flex-col items-start justify-start py-0 pr-1.5 pl-0 box-border text-mediumpurple">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[13px] z-[1] mq450:text-base">
                    B
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start py-0 pr-2.5 pl-0">
                  <div className="h-[23px] relative font-semibold inline-block shrink-0 min-w-[10px] whitespace-nowrap z-[1] mq450:text-base">
                    C
                  </div>
                </div>
                <div className="w-5 shrink-0 flex flex-col items-start justify-start py-0 pr-[5px] pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[15px] z-[1] mq450:text-base">
                    D
                  </div>
                </div>
                <div className="w-[18px] shrink-0 flex flex-col items-start justify-start py-0 pr-[7px] pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[11px] z-[1] mq450:text-base">
                    E
                  </div>
                </div>
                <div className="w-4 shrink-0 flex flex-col items-start justify-start py-0 pr-[5px] pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[11px] z-[1] mq450:text-base">
                    F
                  </div>
                </div>
                <div className="w-[18px] shrink-0 flex flex-col items-start justify-start py-0 pr-0.5 pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[16px] z-[1] mq450:text-base">
                    G
                  </div>
                </div>
                <div className="w-[17px] shrink-0 flex flex-col items-start justify-start py-0 pr-0.5 pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[15px] z-[1] mq450:text-base">
                    H
                  </div>
                </div>
                <div className="w-[15px] shrink-0 flex flex-col items-start justify-start py-0 pr-[9px] pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[6px] z-[1] mq450:text-base">
                    I
                  </div>
                </div>
                <div className="w-[18px] shrink-0 flex flex-col items-start justify-start py-0 pr-1.5 pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[12px] z-[1] mq450:text-base">
                    J
                  </div>
                </div>
                <div className="w-[22px] shrink-0 flex flex-col items-start justify-start py-0 pr-2 pl-0 box-border">
                  <b className="self-stretch h-[23px] relative inline-block shrink-0 min-w-[14px] z-[1] mq450:text-base">
                    K
                  </b>
                </div>
                <div className="w-4 shrink-0 flex flex-col items-start justify-start py-0 pr-1.5 pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[10px] z-[1] mq450:text-base">
                    L
                  </div>
                </div>
                <div className="w-[19px] shrink-0 flex flex-col items-start justify-start py-0 pr-px pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[18px] z-[1] mq450:text-base">
                    M
                  </div>
                </div>
                <div className="w-[17px] shrink-0 flex flex-col items-start justify-start py-0 pr-0.5 pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[15px] z-[1] mq450:text-base">
                    N
                  </div>
                </div>
                <div className="w-[18px] shrink-0 flex flex-col items-start justify-start py-0 pr-0.5 pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[16px] z-[1] mq450:text-base">
                    O
                  </div>
                </div>
                <div className="w-4 shrink-0 flex flex-col items-start justify-start py-0 pr-[3px] pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[13px] z-[1] mq450:text-base">
                    P
                  </div>
                </div>
                <div className="w-[17px] shrink-0 flex flex-col items-start justify-start py-0 pr-px pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[16px] z-[1] mq450:text-base">
                    Q
                  </div>
                </div>
                <div className="w-5 shrink-0 flex flex-col items-start justify-start py-0 pr-[7px] pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[13px] z-[1] mq450:text-base">
                    R
                  </div>
                </div>
                <div className="w-[15px] shrink-0 flex flex-col items-start justify-start py-0 pr-0.5 pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[13px] z-[1] mq450:text-base">
                    S
                  </div>
                </div>
                <div className="h-[23px] w-3 relative font-semibold inline-block shrink-0 min-w-[12px] z-[1] mq450:text-base">
                  T
                </div>
                <div className="w-[19px] shrink-0 flex flex-col items-start justify-start py-0 pr-[5px] pl-0 box-border">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[14px] z-[1] mq450:text-base">
                    U
                  </div>
                </div>
                <div className="w-[15px] shrink-0 flex flex-col items-start justify-start">
                  <div className="self-stretch h-[23px] relative font-semibold inline-block shrink-0 min-w-[15px] z-[1] mq450:text-base">
                    V
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start py-0 pr-[5px] pl-0">
                  <div className="h-[23px] relative font-semibold inline-block shrink-0 min-w-[15px] whitespace-nowrap z-[1] mq450:text-base">
                    W
                  </div>
                </div>
                <div className="h-[23px] w-3 relative font-semibold inline-block shrink-0 min-w-[12px] z-[1] mq450:text-base">
                  Z
                </div>
              </div>
            </div>
          </div>
          <div className="w-[400px] flex flex-col items-start justify-start max-w-full">
            <h1 className="m-0 w-9 relative text-inherit font-semibold font-inherit inline-block min-w-[36px] mq450:text-5xl mq900:text-13xl">
              #
            </h1>
            <div className="self-stretch flex flex-row items-start justify-end mt-[-1px] text-5xl">
              <div className="w-[278px] relative [text-decoration:underline] font-medium inline-block mq450:text-lgi">
                2 factor authentication
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-3.5 pl-[5px] box-border max-w-full text-left text-5xl">
            <div className="flex-1 flex flex-col items-start justify-start gap-[44px] max-w-full mq900:gap-[22px_44px]">
              <div className="self-stretch h-px flex flex-row items-start justify-end max-w-full">
                <div className="self-stretch w-[964px] relative box-border max-w-full border-t-[1px] border-solid border-gainsboro-300" />
              </div>
              <div className="w-[543px] flex flex-col items-start justify-start gap-[19px] max-w-full">
                <div className="w-[358px] flex flex-col items-start justify-start gap-[7px] max-w-full text-center text-21xl">
                  <h1 className="m-0 w-[29px] relative text-inherit font-semibold font-inherit inline-block min-w-[29px] mq450:text-5xl mq900:text-13xl">
                    A
                  </h1>
                  <div className="self-stretch flex flex-row items-start justify-end text-left text-5xl">
                    <div className="flex flex-col items-start justify-start gap-[19px]">
                      <div className="relative [text-decoration:underline] font-medium mq450:text-lgi">
                        access provisioning
                      </div>
                      <div className="relative [text-decoration:underline] font-medium mq450:text-lgi">
                        account sharing
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-end py-0 px-[3px]">
                  <div className="relative [text-decoration:underline] font-medium mq450:text-lgi">
                    active directory certificate services
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-end">
                  <div className="relative [text-decoration:underline] font-medium mq450:text-lgi">
                    active directory federation services
                  </div>
                </div>
                <div className="w-[495px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
                  <div className="relative [text-decoration:underline] font-medium mq450:text-lgi">
                    ad certificate services
                  </div>
                </div>
                <div className="w-[294px] flex flex-row items-start justify-center py-0 px-5 box-border">
                  <div className="relative [text-decoration:underline] font-medium inline-block min-w-[66px] mq450:text-lgi">
                    ad cs
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[7px] box-border max-w-full text-left text-5xl">
            <div className="flex-1 flex flex-col items-start justify-start gap-[44px] max-w-full mq900:gap-[22px_44px]">
              <div className="self-stretch h-px flex flex-row items-start justify-end max-w-full">
                <div className="self-stretch w-[964px] relative box-border max-w-full border-t-[1px] border-solid border-gainsboro-300" />
              </div>
              <div className="w-[579px] flex flex-col items-start justify-start gap-[7px] max-w-full">
                <h1 className="m-0 w-[26px] relative text-21xl font-semibold font-inherit text-mediumpurple text-center inline-block min-w-[26px] mq450:text-5xl mq900:text-13xl">
                  B
                </h1>
                <div className="w-[494px] flex flex-row items-start justify-center pt-0 px-5 pb-3 box-border max-w-full">
                  <div className="relative [text-decoration:underline] font-medium mq450:text-lgi">
                    behavioral biometrics
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-end pt-0 px-0 pb-3">
                  <div className="relative [text-decoration:underline] font-medium mq450:text-lgi">
                    behavioral bio-metrics authentication
                  </div>
                </div>
                <div className="w-[410px] flex flex-row items-start justify-center pt-0 px-5 pb-3 box-border max-w-full">
                  <div className="relative [text-decoration:underline] font-medium mq450:text-lgi">
                    bilinear pairing
                  </div>
                </div>
                <div className="w-96 flex flex-row items-start justify-center pt-0 px-5 pb-3 box-border max-w-full">
                  <div className="relative [text-decoration:underline] font-medium mq450:text-lgi">
                    black energy
                  </div>
                </div>
                <div className="w-[425px] flex flex-row items-start justify-center pt-0 px-5 pb-3 box-border max-w-full">
                  <div className="relative [text-decoration:underline] font-medium mq450:text-lgi">
                    blowfish security
                  </div>
                </div>
                <div className="w-[565px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
                  <div className="relative [text-decoration:underline] font-medium mq450:text-lgi">
                    brute force password attack
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FrameComponent1 />
    </div>
  );
};

export default Glossary;
