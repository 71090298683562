const FrameComponent2 = ({ whyEMD }) => {
  return (
    <section className="w-[768px] flex flex-col items-start justify-start gap-[36.5px] max-w-full text-left text-26xl text-color1 font-a mq450:gap-[18px_36.5px]">
      <div className="self-stretch flex flex-col items-start justify-start gap-[30.5px] max-w-full mq450:gap-[15px_30.5px]">
        <div className="w-[638px] flex flex-row items-start justify-start py-0 px-[3px] box-border max-w-full">
          <div className="flex-1 flex flex-col items-start justify-start gap-[19px] max-w-full">
            <h1 className="m-0 self-stretch h-[46px] relative text-inherit font-bold font-inherit inline-block shrink-0 [-webkit-text-stroke:1px_#000] mq450:text-8xl mq900:text-17xl">
              {whyEMD}
            </h1>
            <div className="w-[350px] flex flex-row flex-wrap items-start justify-start max-w-full [row-gap:20px] text-lg">
              <b className="flex-1 relative inline-block min-w-[132px]">
                Written by John doe
              </b>
              <div className="relative text-color whitespace-pre-wrap">
                Monday May 20
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-gainsboro-100" />
      </div>
      <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[3px] box-border max-w-full text-base text-color">
        <div className="flex-1 relative inline-block max-w-full">
          <p className="m-0">
            <span>
              <span>
                Section 1.10.32 of "de Finibus Bonorum et Malorum", written by
                Cicero in 45 BC
              </span>
            </span>
          </p>
          <p className="m-0">
            <span>
              <span>
                "Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia
                consequuntur magni dolores eos qui ratione voluptatem sequi
                nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
                sit amet, consectetur, adipisci velit, sed quia non numquam eius
                modi tempora incidunt ut labore et dolore magnam aliquam quaerat
                voluptatem. Ut enim ad minima veniam, quis nostrum
                exercitationem ullam corporis suscipit laboriosam, nisi ut
                aliquid ex ea commodi consequatur? Quis autem vel eum iure
                reprehenderit qui in ea voluptate velit esse quam nihil
                molestiae consequatur, vel illum qui dolorem eum fugiat quo
                voluptas nulla pariatur?"
              </span>
            </span>
          </p>
          <p className="m-0">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="m-0">
            <span>
              <span>1914 translation by H. Rackham</span>
            </span>
          </p>
          <p className="m-0">
            <span>
              <span>
                "But I must explain to you how all this mistaken idea of
                denouncing pleasure and praising pain was born and I will give
                you a complete account of the system, and expound the actual
                teachings of the great explorer of the truth, the master-builder
                of human happiness. No one rejects, dislikes, or avoids pleasure
                itself, because it is pleasure, but because those who do not
                know how to pursue pleasure rationally encounter consequences
                that are extremely painful. Nor again is there anyone who loves
                or pursues or desires to obtain pain of itself, because it is
                pain, but because occasionally circumstances occur in which toil
                and pain can procure him some great pleasure. To take a trivial
                example, which of us ever undertakes laborious physical
                exercise, except to obtain some advantage from it? But who has
                any right to find fault with a man who chooses to enjoy a
                pleasure that has no annoying consequences, or one who avoids a
                pain that produces no resultant pleasure?"
              </span>
            </span>
          </p>
          <p className="m-0">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="m-0 text-color1">
            <span>
              <i className="font-bold">
                Section 1.10.33 of "de Finibus Bonorum et Malorum"
              </i>
            </span>
          </p>
          <p className="m-0">
            <span>
              <i className="font-bold">&nbsp;</i>
            </span>
          </p>
          <p className="m-0">
            <span>
              <span>
                <span>
                  "At vero eos et accusamus et iusto odio dignissimos ducimus
                  qui blanditiis praesentium voluptatum deleniti atque corrupti
                  quos dolores et quas molestias excepturi sint occaecati
                  cupiditate non provident, similique sunt in culpa qui officia
                  deserunt mollitia animi, id est laborum et dolorum fuga. Et
                  harum quidem rerum facilis est et expedita distinctio. Nam
                  libero tempore, cum soluta nobis est eligendi optio cumque
                  nihil impedit quo minus id quod maxime placeat facere
                  possimus, omnis voluptas assumenda est, omnis dolor
                  repellendus. Temporibus autem quibusdam et aut officiis
                  debitis aut rerum necessitatibus saepe eveniet ut et
                  voluptates repudiandae sint et molestiae non recusandae.
                  Itaque earum rerum hic tenetur a sapiente delectus, ut aut
                  reiciendis voluptatibus maiores alias consequatur aut
                  perferendis doloribus asperiores repellat."
                </span>
              </span>
            </span>
          </p>
          <p className="m-0 text-color1">
            <span>
              <span>
                <span>&nbsp;</span>
              </span>
            </span>
          </p>
          <p className="m-0 text-11xl">
            <span>
              <b>Section</b>
            </span>
          </p>
          <p className="m-0">
            <span>
              <b>&nbsp;</b>
            </span>
          </p>
          <p className="m-0">
            <span>
              <span>
                "At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores et quas molestias excepturi sint occaecati cupiditate
                non provident, similique sunt in culpa qui officia deserunt
                mollitia animi, id est laborum et dolorum fuga. Et harum quidem
                rerum facilis est et expedita distinctio. Nam libero tempore,
                cum soluta nobis est eligendi optio cumque nihil impedit quo
                minus id quod maxime placeat facere possimus, omnis voluptas
                assumenda est, omnis dolor repellendus. Temporibus autem
                quibusdam et aut officiis debitis aut rerum necessitatibus saepe
                eveniet ut et voluptates repudiandae sint et molestiae non
                recusandae. Itaque earum rerum hic tenetur a sapiente delectus,
                ut aut reiciendis voluptatibus maiores alias consequatur aut
                perferendis doloribus asperiores repellat."
              </span>
            </span>
          </p>
          <p className="m-0 text-color1">
            <span>
              <span>&nbsp;</span>
            </span>
          </p>
          <p className="m-0 text-11xl">
            <span>
              <b>Section 1.10.33</b>
            </span>
          </p>
          <p className="m-0">
            <span>
              <b>&nbsp;</b>
            </span>
          </p>
          <p className="m-0">
            <span>
              <span>"</span>
              <span>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores et quas molestias excepturi sint occaecati cupiditate
                non provident, similique sunt in culpa qui officia deserunt
                mollitia animi, id est laborum et dolorum fuga. Et harum quidem
                rerum facilis est et expedita distinctio. Nam libero tempore,
                cum soluta nobis est eligendi optio cumque nihil impedit quo
                minus id quod maxime placeat facere possimus, omnis voluptas
                assumenda est, omnis dolor repellendus. Temporibus autem
                quibusdam et aut officiis debitis aut rerum necessitatibus saepe
                eveniet ut et voluptates repudiandae sint et molestiae non
                recusandae. Itaque earum rerum hic tenetur a sapiente delectus,
                ut aut reiciendis voluptatibus maiores alias consequatur aut
                perferendis doloribus asperiores repellat."
              </span>
            </span>
          </p>
          <p className="m-0">
            <span>
              <span>
                Section 1.10.33 of "de Finibus Bonorum et Malorum", written by
                Cicero in 45 BC
              </span>
            </span>
          </p>
          <p className="m-0">
            <span>
              <span>
                "At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores et quas molestias excepturi sint occaecati cupiditate
                non provident, similique sunt in culpa qui officia deserunt
                mollitia animi, id est laborum et dolorum fuga. Et harum quidem
                rerum facilis est et expedita distinctio. Nam libero tempore,
                cum soluta nobis est eligendi optio cumque nihil impedit quo
                minus id quod maxime placeat facere possimus, omnis voluptas
                assumenda est, omnis dolor repellendus. Temporibus autem
                quibusdam et aut officiis debitis aut rerum necessitatibus saepe
                eveniet ut et voluptates repudiandae sint et molestiae non
                recusandae. Itaque earum rerum hic tenetur a sapiente delectus,
                ut aut reiciendis voluptatibus maiores alias consequatur aut
                perferendis doloribus asperiores repellat."
              </span>
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent2;
