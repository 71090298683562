import { useTranslation } from "react-i18next";

const Test = () => {
  const { t } = useTranslation("Philosophy");

  return (
    <div className="self-stretch flex flex-col lg:flex-row flex-wrap items-center justify-center py-10 px-5 sm:px-10 lg:px-20 gap-5 max-w-full text-left text-xl text-teal-green font-open-sans xl:py-5 xl:px-20">
      <img
        className="shadow-lg rounded-3xl w-full lg:w-1/2 object-cover mb-10 lg:mb-0 xs:h-[300px] md:max-w-[819px] md:h-[400px] lg:h-[900px] lg:max-w-[644px]"
        loading="lazy"
        alt=""
        src="/artwork1.jpg"
      />
      <div className="flex-1 flex flex-col items-start justify-start max-w-full lg:pl-10">
        <h1 className="text-[32px] sm:text-[48px] lg:text-[50px] font-normal font-source-sans-pro">
          {t("title")}
        </h1>
        <div className="leading-8 font-extrabold text-[24px] xs:text-[20px] sm:text-[25px] md:text-[25px] lg:text-[20px] mb-24 ">
          {t("subheading")}
        </div>

        <div className="leading-8 text-base sm:text-lg lg:text-xl  text-justify mb-9">
          {t("paragraph1")}
        </div>
        <div className="leading-8 text-base sm:text-lg lg:text-xl text-justify">
          {t("paragraph2")}
        </div>
      </div>
      <div className="w-full lg:w-1/2 flex flex-col items-start justify-start mb-11 text-justify  ">
        <div className="leading-8 text-base sm:text-lg lg:text-xl">
          {t("paragraph3")}
        </div>
      </div>
    </div>
  );
};

export default Test;
