import Header from "../components/Header";
import FrameComponent from "../components/FrameComponent";

const Contact = () => {
    return (
      <div className="w-full relative bg-light-grey overflow-hidden flex flex-col items-center justify-center tracking-[normal]">
       
          <h1 className="text-4xl font-bold text-ocean-blue">Team Members</h1>
        

        

        
        
      </div>
    );
};

export default Contact;
