// InvestingWithGrandviewContent.js
import React from "react";
import Flyout from "../components/Flyout";
import { Link } from "react-router-dom";
const AboutContent = () => {
  return (
    <div className="text-lg w-64  p-6 shadow-xl z-50 font-open-sans ">
      <div className="mb-3 space-y-5 relative   ">
        <a className="block  ">
          <Flyout>
            <Link
              to="/member"
              className="  text-teal-green text-center  transition-colors duration-200 ease-in-out no-underline  "
            >
              TEAM MEMBERS
            </Link>
          </Flyout>
        </a>
        <a className="block  ">
          <Flyout>
            <Link
              to="#"
              className=" text-teal-green text-center  transition-colors duration-200 ease-in-out no-underline  "
            >
              LEGAL & COMPLIANCE TEAM MEMBERS
            </Link>
          </Flyout>
        </a>
      </div>
    </div>
  );
};

export default AboutContent;
