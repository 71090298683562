import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const AdaptiveHero = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // 简单的移动设备检测
    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      setIsMobile(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase()));
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (!isMobile && !playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      playerRef.current = videojs(videoElement, {
        controls: true,
        autoplay: 'muted',
        preload: 'auto',
        loop: true,
        muted: true,
        responsive: true,
        fluid: true,
        aspectRatio: '16:9',
        playsinline: true,
        sources: [{
          src: '/vancouver1.mp4',
          type: 'video/mp4'
        }]
      }, () => {
        console.log('Player is ready');
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [isMobile]);

  if (isMobile) {
    return (
      <div className="shadow-lg relative rounded-3xl overflow-hidden w-full">
        <div className="aspect-w-16 aspect-h-9 h-[306px]">
          <img 
            src="/vancouver.gif" 
            alt="Vancouver Cityscape" 
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="shadow-lg relative rounded-3xl overflow-hidden w-full">
      <div className="aspect-w-16 aspect-h-9 sm:aspect-auto sm:h-full h-[306px] sm:aspect-w-16 sm:aspect-h-9">
        <div data-vjs-player className="w-full h-full">
          <video 
            ref={videoRef} 
            className="video-js vjs-big-play-centered"
            playsInline
            webkit-playsinline="true"
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdaptiveHero;