import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Background from "../components/Background";
import Blogs from "../components/Blogs";
import VideoPlayer2 from "../components/Videopart2";
import VideoPlayer1 from "../components/Videopart1";
import HomepageFAQs from "../components/HomeFAQS";
import { useTranslation } from "react-i18next";

const HomepageDesktop = () => {
  const { t } = useTranslation("blog");
  return (
    <div
      className="leading-normal w-full relative bg-light-grey overflow-visible flex flex-col items-start justify-start tracking-normal"
    >
      <section
        className="bg-light-grey overflow-hidden flex flex-col items-center justify-start py-20 px-4 sm:px-6 lg:px-8 xl:px-16 box-border gap-[80px] max-w-full text-left text-21xl text-anchor-grey font-source-sans-pro
                   mq975:pt-[22px] mq975:pb-[22px] mq1500:pt-[34px] mq1300:pb-[34px] mq450:py-[20px] mq450:px-[10px]"
      ><VideoPlayer1 />
        <Intro />
        <Hero />
        
        <Background />
        <h1
          className="text-teal-green m-0 w-full max-w-4xl text-41xl leading-[75px] font-normal font-inherit text-center 
                     mq975:text-29xl mq975:leading-[60px] mq450:text-17xl mq450:leading-[45px]"
        >
          {t("title")}
        </h1>
        <Blogs />
        <VideoPlayer2 />
        <HomepageFAQs />
        <div className="self-stretch h-14 overflow-hidden shrink-0" />
        <div className="self-stretch h-14 overflow-hidden shrink-0" />
      </section>
    </div>
  );
};

export default HomepageDesktop;
