import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Intro = () => {
  const { t } = useTranslation("intro");
  useEffect(() => {
    const videoElement = document.querySelector("video");

    const disableClick = (event) => {
      if (window.innerWidth <= 450) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    videoElement.addEventListener("click", disableClick);

    // 组件卸载时移除事件监听
    return () => {
      videoElement.removeEventListener("click", disableClick);
    };
  }, []);

  return (
    <div
      className="self-stretch flex flex-row flex-wrap items-center justify-center pt-[70px] px-[155.5px] pb-0 box-border 
    gap-[30px] max-w-full text-left text-xl text-teal-green font-open-sans mq975:gap-[15px]
    mq975:pl-[38px] mq975:pt-[45px] mq975:pr-[38px] mq975:box-border mq1500:pl-[77px]
    mq1500:pr-[77px] mq1500:box-border mq450:flex-col   
    mq450:px-[10px]"
    >
      <div className="flex-1 flex flex-col items-start justify-start gap-[30px] min-w-[418px] max-w-full mq975:min-w-full mq450:flex-col">
        <h1
          className="m-0 self-stretch relative text-41xl leading-[75px] font-normal font-source-sans-pro mq975:text-29xl 
                        mq975:leading-[60px] mq450:text-[30px] mq450:leading-[45px]"
        >
          {t("head1")}
        </h1>
        <h2 className="self-stretch relative text-lg sm:text-xl md:text-2xl lg:text-3xl leading-normal font-bold text-dark-green mb-2">
          {t("head2")}
        </h2>
        <div className="self-stretch text-justify relative leading-[34px] mq450:text-[12px] mq450:leading-[27px]">
          {t("body")}
        </div>

        <button
          className="button flex items-center justify-center cursor-pointer [border:none] py-[30px] px-[30px] 
          bg-teal-green hover:bg-mint-green w-[171px] rounded-31xl overflow-hidden shrink-0
          flex-col box-border whitespace-nowrap mq450:py-[15px]
          mq450:px-[15px] mq450:w-[189px] mq450:h-[48px] transition-colors duration-300"
        >
          <b className="text-mint-green h-[27px] relative text-xl inline-block font-aeroport text-center mq450:text-[15px] ">
            Learn More
          </b>
        </button>
      </div>
    </div>
  );
};

export default Intro;
