import Header from "../components/Header";
import FrameComponent2 from "../components/FrameComponent2";
import FrameComponent1 from "../components/FrameComponent1";

const WhatIsEMD = () => {
  return (
    <div className="w-full relative bg-light-grey overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-2.5 box-border tracking-[normal]">
      
      <main className="self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-2.5 box-border max-w-full">
        <FrameComponent2 whyEMD="What is EMD?" />
      </main>
      
    </div>
  );
};

export default WhatIsEMD;
