import Market from "../pagecontent/market";

const TheGrandViewPhilosophy = () => {
  return (
    <div className="w-full relative bg-light-grey overflow-visible flex flex-col items-start justify-start tracking-[normal]">
      <section className="self-stretch bg-light-grey overflow-hidden flex flex-col items-center justify-start py-20 px-5 sm:px-10 lg:px-20 box-border gap-10 max-w-full text-left text-xl text-anchor-grey font-source-sans-pro">
        <img
          src="/van_2_6.jpg" // 替换为你的图片路径
          alt="Description of Image" // 替换为你的图片描述
          className="w-full h-auto max-h-[399px] mb-10 object-cover rounded-3xl"
        />
        <div
          className="shadow-lg relative flex items-start justify-center box-border max-w-full mx-auto rounded-3xl overflow-hidden"
          style={{ width: "2028px", minHeight: "1600px" }} // 使用 minHeight 替代 height
        >
          <Market />
        </div>
      </section>
    </div>
  );
};

export default TheGrandViewPhilosophy;
