import Header from "../components/Header";
import ToolsPaneComp from "../components/ToolsPaneComp";
import PageElementComp from "../components/PageElementComp";

const Blog = () => {
  return (
    <div className="w-full relative overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[30px] box-border tracking-[normal]">
      <Header
        langMenuWidth="1024px"
        menuItemAlignSelf="stretch"
        investingWithGrandviewFlex="1"
        menuItemAlignSelf1="stretch"
        menuItemLineHeight="23px"
      />
      <main className="self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-2.5 box-border max-w-full">
        <ToolsPaneComp />
      </main>
      <PageElementComp />
    </div>
  );
};

export default Blog;
