const FrameComponent = () => {
  return (
    <footer className="w-full overflow-x-auto flex flex-col lg:flex-row items-start justify-start py-2.5 pr-10 pl-6 box-border lg:gap-20 text-left bg-light-grey text-teal-green font-abeezee lg:pr-12 xl:pr-24 ">
      <div className="self-stretch lg:w-1/4 shrink-0 flex flex-col items-start justify-center py-0 pr-8 pl-0 box-border gap-2.5 text-teal-green font-open-sans sm:pr-6 sm:pl-4 lg:pr-12 xl:pr-16">
        <img
          className="h-auto w-[180px] xs:w-[200px] sm:w-[250px] md:w-[280px] lg:w-[300px] relative object-contain"
          loading="lazy"
          alt=""
          src="/grandviewcap-logo-logo-b-2@2x.png"
        />
        <div className="leading-[34px] text-[12px] sm:text-[12px] lg:text-[20px] lg:leading-[30px] gap-[15px]">
          <p className="m-0">admin@grandviewcapital.co</p>
          <p className="m-0">(604)-897-4836</p>
          <p className="m-0">1205-6388 No.3 Rd,</p>
          <p className="m-0">Richmond BC V6Y 0L4</p>
        </div>
      </div>
      <div className="mt-3 flex flex-col items-start justify-center gap-5 lg:w-1/6 lg:gap-10 ">
        <b className="leading-[34px] text-[12px] sm:text-[12px] lg:text-[20px] lg:leading-[30px] ">
          Investing
        </b>
        <div className="flex flex-col gap-2.5 leading-[34px] text-[12px] sm:text-[12px] lg:text-[20px] lg:leading-[30px] font-open-sans">
          <p className="m-0">Investment Philosophy</p>
          <p className="m-0">Why EMD?</p>
          <p className="m-0">Investment Options</p>
        </div>
      </div>
      <div className="mt-3 w-full lg:w-1/6 shrink-0 flex flex-col items-start justify-center gap-5 lg:gap-10">
        <b className="leading-[34px] text-[12px] sm:text-[12px] lg:text-[20px] lg:leading-[30px]">
          About
        </b>
        <div className="flex flex-col gap-2.5 leading-[34px] text-[12px] sm:text-[12px] lg:text-[20px] lg:leading-[30px] font-open-sans">
          <p className="m-0">About Us</p>
          <p className="m-0">Our Advisory Team</p>
          <p className="m-0">Accreditations</p>
          <p className="m-0">Contact Us</p>
        </div>
      </div>
      <div className="mt-3 w-full lg:w-1/6 shrink-0 flex flex-col items-start justify-center gap-5 lg:gap-10 sm:gap-10">
        <b className="leading-[34px] text-[12px] sm:text-[12px] lg:text-[20px] lg:leading-[30px]">
          Legal
        </b>
        <div className="flex flex-col gap-2.5 leading-[34px] text-[12px] sm:text-[12px] lg:text-[20px] lg:leading-[30px] font-open-sans">
          <p className="m-0">EMD Regulations</p>
          <p className="m-0">Legal Disclosures</p>
          <p className="m-0">Terms of Use</p>
          <p className="m-0">Privacy Policy</p>
        </div>
      </div>
    </footer>
  );
};

export default FrameComponent;
