import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import HomepageDesktop from "./pages/HomepageDesktop";
import Glossary from "./pages/Glossary";
import Blog from "./pages/Blog";
import WhyEMD from "./pages/WhyEMD";
import WhatIsEMD from "./pages/WhatIsEMD";
import Test from "./pages/test";
import Drop from "./pages/Drop";
import Layout from "./pages/Layout";
import Contact from "./pages/Contact";
import Member from "./pages/Member";
import TheGrandViewPhilosophy from "./pages/TheGrandviewPhilosophy";
import StartRelation from "./pages/StartRelation";
import Market from "./pages/Market";
import { Survey } from "survey-react-ui";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  

  return (
    <div className="pt-40 bg-light-grey"> {/* Adjust this padding based on your header height */}
    <Routes>
      <Route path="/" element={<HomepageDesktop />} />
      <Route path="/glossary" element={<Glossary />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/why-emd" element={<WhyEMD />} />
      <Route path="/what-is-emd" element={<WhatIsEMD />} />
      <Route path="/test" element={<Test />} />
      <Route path="/drop" element={<Drop />} />
      <Route path="/layout" element={<Layout />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/member" element={<Member />} />
      <Route path="/survey" element={<Survey />} />
      <Route path="/the-grandview-philosophy" element={<TheGrandViewPhilosophy />} />
      <Route path="/start-relation" element={<StartRelation />} />
      <Route path="/market" element={<Market />} />

    </Routes>
    </div>
  );
}
export default App;
